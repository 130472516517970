import { cva, type VariantProps } from 'class-variance-authority'

export type ChipVariantProps = VariantProps<typeof chipVariants>

export type ChipIntent = NonNullable<ChipVariantProps['intent']>

export type ChipSize = NonNullable<ChipVariantProps['size']>

export type ChipColor = NonNullable<ChipVariantProps['color']>

export const chipVariants = cva([
  'flex',
  'items-center',
  'justify-center',
  'outline',
  'text-blurple-500',
  'w-fit',
  'ease-in-out',
  'duration-300',
  'transition-all',
], {
  variants: {
    intent: {
      filled: ['outline-2', 'outline-transparent'],
      outlined: ['outline-1', 'outline-[currentColor]'],
      neutral: ['bg-inherit', 'text-inherit'],
    },
    size: {
      small: ['text-caption', 'py-[0.3125rem]', 'px-2xs', 'gap-2xs', 'rounded'],
      large: ['text-small', 'py-xs', 'px-s', 'gap-xs', 'rounded-lg'],
    },
    color: {
      blurple: ['text-blurple-500'],
      moss: ['text-moss-500'],
      carbon: ['text-carbon-500'],
    },
  },
  compoundVariants: [
    {
      intent: 'filled',
      color: 'blurple',
      class: ['text-blurple-500', 'bg-blurple-50', 'hover:outline-blurple-300', 'focus:outline-blurple-200'],
    },
    {
      intent: 'outlined',
      color: 'blurple',
      class: ['text-blurple-500', 'hover:outline-2', 'hover:outline-blurple-300', 'focus:outline-blurple-200'],
    },
    {
      intent: 'neutral',
      color: 'blurple',
      class: ['text-blurple-500', 'bg-inherit', 'text-inherit'],
    },
    {
      intent: 'filled',
      color: 'moss',
      class: ['text-moss-500', 'bg-moss-200', 'focus:outline-moss-200', 'hover:outline-moss-500'],
    },
    {
      intent: 'outlined',
      color: 'moss',
      class: ['text-moss-500', 'hover:outline-2', 'hover:outline-moss-200', 'focus:outline-moss-200'],
    },
    {
      intent: 'neutral',
      color: 'moss',
      class: ['text-moss-500', 'bg-inherit', 'text-inherit'],
    },
    {
      intent: 'filled',
      color: 'carbon',
      class: ['text-carbon-500', 'bg-white', 'hover:outline-moss-500'],
    },
    {
      intent: 'outlined',
      color: 'carbon',
      class: ['text-carbon-500', 'outline-transparent'],
    },
    {
      intent: 'neutral',
      color: 'carbon',
      class: ['text-carbon-500', 'bg-inherit', 'text-inherit'],
    },
  ],
})
