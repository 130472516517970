<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { ChipColor, ChipIntent } from '~/utils/chip'
import { KsIcon } from '@aschehoug/kloss'

const props = withDefaults(defineProps<{
  variant: ChipIntent
  size: ChipSize
  color?: ChipColor
  iconLeft?: IconDefinition
  iconRight?: IconDefinition
}>(), {
  variant: 'filled',
  size: 'large',
  color: 'blurple',
  iconLeft: undefined,
  iconRight: undefined,
})

const iconSize = computed(() => props.size === 'small' ? 'size-4' : 'size-5')
</script>

<template>
  <component
    :is="$attrs.onClick ? 'button' : 'div'"
    :class="[
      chipVariants({
        intent: props.variant,
        size,
        color,
      }),
    ]"
    v-bind="$attrs"
  >
    <KsIcon v-if="iconLeft" :icon="iconLeft" :class="iconSize" class="text-[currentColor]" />
    <slot />
    <KsIcon v-if="iconRight" :icon="iconRight" :class="iconSize" class="text-[currentColor]" />
  </component>
</template>
